import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATE_FORMAT, EMPTY } from "constants/common";
import { LessonProgressStatisticsModel } from "types/model/reporting";

const columnConfig: ColumnType<LessonProgressStatisticsModel>[] = [
  {
    title: "Customer email",
    dataIndex: "customerEmail",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Customer name",
    dataIndex: "customerName",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Group",
    dataIndex: "customerGroupName",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Subusers",
    dataIndex: "subUserNames",
    ellipsis: true,
    render: (value) => value?.join(",") ?? EMPTY,
  },
  {
    title: "Course",
    dataIndex: "courseName",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Last activity date",
    dataIndex: "lastActivityDate",
    ellipsis: true,
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true,
    render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
  },
  {
    title: "License status",
    dataIndex: "licenseStatus",
    ellipsis: true,
    render: (value) =>
      value
        ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        : EMPTY,
  },
];

export default columnConfig;
