import { DeliveryStatus } from "types/model/global";
import { VoucherDeal, VoucherType } from "../../model/assignment";
import Page from "../../page";

export interface VoucherDistributionListResponse {
  adminGroupId: number;

  adminGroupName: string;

  description: string;

  expirationDate: number;

  startDate: number;

  voucherAmount: number;

  voucherDeal: VoucherDeal;

  voucherGroupId: string;

  voucherType?: VoucherType;

  hasExpired: boolean;

  aboutToExpire: boolean;
}

/* SINGLE and SEAT */
export interface AssignmentAndRedemptionListResponse {
  voucherId: number;

  voucherCode: string;

  assigneeName?: string;

  assigneeEmail?: string;

  redeemerEmail?: string;

  redeemedCourse?: string;

  hasSentEmail?: boolean;

  redemptionId: number;

  assignmentId: number;

  assignedDate: number;

  accountCreated?: boolean;

  /* single voucher doesn't has this field. */
  productId: number;

  isDeleted: boolean;

  inactiveDays: number;

  deliveryStatus: DeliveryStatus | null;

  deliveryDetail: string | null;

  hasExpired: boolean;

  aboutToExpire: boolean;
}

export interface VoucherSummaryResponse {
  voucherCode: string;

  voucherType: VoucherType;

  voucherId: number;

  totalAmount: number;

  assignedAmount: number;

  redeemedAmount: number;

  description: string;

  invoice: string;

  startDate: number;

  expirationDate: number;

  isOpenEnded: boolean;
}

export interface RedemptionListResponse {
  redeemedCourse: string;

  redeemedDate: number;

  redeemerEmail: string;

  redemptionId: number;

  voucherCode: string;

  voucherId: number;

  isDeleted: boolean;

  inactiveDays: number;

  hasExpired: boolean;

  aboutToExpire: boolean;
}

export interface AssignmentListResponse {
  assigneeName?: string;

  assigneeEmail?: string;

  assignedDate: number;

  hasSentEmail?: boolean;

  deliveryStatus: DeliveryStatus | null;

  deliveryDetail: string | null;

  assignmentId: number;

  isDeleted: boolean;
}

export interface RedeemableProductResponse {
  isbn: string;

  languageId: number;

  languageName: string;

  productId: number;

  productName: string;
}

export interface VoucherCodeInformation {
  adminGroupName: string;

  invoice: string;
}

export interface CheckRenewableInvoiceResponse {
  voucherGroupId: string;
  adminGroupId: number;
  invoice: string;
  voucherDescription: string;
  expiryDate: number;
}

export interface RenewableUserListResponse extends Page<RenewableUser> {
  renewedCount: number;
  isRenewable: boolean;
}

export interface RenewableUser {
  renewSelectId: number;
  customerEmail: string;
  customerName: string;
  currentExpiryDate: number;
  newExpiryDate: number;
  courseName: string;
  status: RenewableStatus;
}

export enum RenewableStatus {
  UNABLE_TO_RENEW = "UNABLE_TO_RENEW",
  NOT_RENEWED = "NOT_RENEWED",
  RENEWED = "RENEWED",
}

export interface ConfirmRenewResponse {
  renewedCount: number;
  newExpiryDate: number;
}
