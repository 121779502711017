import { Divider, Empty, List, Radio, Space } from "antd";
import { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import * as assignmentAPI from "services/assignment";
import { CheckRenewableInvoiceResponse } from "../../../../types/dto/response/assignment";
import Message from "../../../../components/message";

interface IProps {
  adminGroupId: string;
  voucherGroupId: string;
  onInvoiceSelect: (invoice: CheckRenewableInvoiceResponse) => void;
}

const SelectInvoice = ({
  adminGroupId,
  voucherGroupId,
  onInvoiceSelect,
}: IProps) => {
  const [data, setData] = useState<CheckRenewableInvoiceResponse[]>();
  const [selectedInvoice, setSelectedInvoice] = useState<
    CheckRenewableInvoiceResponse
  >();

  useEffect(() => {
    selectedInvoice && onInvoiceSelect(selectedInvoice);
  }, [selectedInvoice]);

  useEffect(() => {
    assignmentAPI
      .checkRenewableInvoice(adminGroupId, voucherGroupId)
      .then((response) => setData(response))
      .catch(Message.error);
  }, [adminGroupId, voucherGroupId]);

  return (
    <Space direction={"vertical"} size={"middle"} style={{ width: "100%" }}>
      <span>
        This screen allows you to renew a user's license, ensuring their
        learning progress is uninterrupted.
      </span>
      <Divider style={{ margin: "0px" }} />
      <span>* Please select an expired or expiring invoice below:</span>
      {_.isEmpty(data) && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data"} />
      )}
      {!_.isEmpty(data) && (
        <List
          dataSource={data}
          itemLayout={"vertical"}
          pagination={{ pageSize: 10 }}
          renderItem={(item: CheckRenewableInvoiceResponse) => (
            <List.Item
              key={`${item.adminGroupId}${item.voucherGroupId}`}
              style={{ border: "none" }}
            >
              <Radio
                onClick={() => setSelectedInvoice(item)}
                checked={
                  selectedInvoice?.adminGroupId === item.adminGroupId &&
                  selectedInvoice?.voucherGroupId === item.voucherGroupId
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ whiteSpace: "normal", wordBreak: "break-all" }}>
                  {formatLabel(item)}
                </span>
              </Radio>
            </List.Item>
          )}
        />
      )}
    </Space>
  );
};

const formatLabel = (invoice: CheckRenewableInvoiceResponse) => {
  const invoiceNumber = `Invoice ${
    _.isEmpty(invoice.invoice) ? "-" : invoice.invoice
  }`;
  const description = `${
    _.isEmpty(invoice.voucherDescription) ? "-" : invoice.voucherDescription
  }`;
  const expiryDate = invoice.expiryDate;
  const date = expiryDate
    ? `End date ${moment(expiryDate).format("YYYY-MM-DD")}`
    : "Open ended";
  return `${invoiceNumber} | ${description} | ${date}`;
};

export default SelectInvoice;
