import instance from "../utils/instance";
import Page from "../types/page";
import {
  AssignmentAndRedemptionListResponse,
  AssignmentListResponse,
  CheckRenewableInvoiceResponse,
  ConfirmRenewResponse,
  RedeemableProductResponse,
  RedemptionListResponse,
  RenewableStatus,
  RenewableUserListResponse,
  VoucherCodeInformation,
  VoucherDistributionListResponse,
  VoucherSummaryResponse,
} from "../types/dto/response/assignment";
import {
  AssignmentAndRedemptionListParams,
  AssignmentListParams,
  BulkSwitchGroupParams,
  RedemptionListParams,
  RevokingRequest,
  SeatReassignRequest,
  SendSeatEmail,
  SwitchCourseParams,
  SwitchGroupParams,
  VoucherAssignmentRequest,
  VoucherAvailableAmountParams,
  VoucherDistributeRequest,
  VoucherDistributionListParams,
  VoucherEmailParams,
  VoucherReassignmentRequest,
  VoucherSummaryParams,
} from "../types/dto/request/assignment";

export async function fetchAll(
  adminGroupId: string,
  params: VoucherDistributionListParams
) {
  const url = `/vouchers/distributions/${adminGroupId}`;
  return instance.get<Page<VoucherDistributionListResponse>>(url, { params });
}

export async function fetchAssignmentAndRedemptionList(
  params: AssignmentAndRedemptionListParams
) {
  const url = `/vouchers/assignments-and-redemptions`;
  return instance.get<Page<AssignmentAndRedemptionListResponse>>(url, {
    params,
  });
}

export async function fetchSeatAssignmentList(
  params: AssignmentAndRedemptionListParams
) {
  const url = `/vouchers/seat-assignments`;
  return instance.get<Page<AssignmentAndRedemptionListResponse>>(url, {
    params,
  });
}

export async function fetchAssignmentList(params: AssignmentListParams) {
  const url = `/vouchers/assignments`;
  return instance.get<Page<AssignmentListResponse>>(url, { params });
}

export async function fetchRedemptionList(params: RedemptionListParams) {
  const url = `/vouchers/redemptions`;
  return instance.get<Page<RedemptionListResponse>>(url, { params });
}

export async function fetchRedeemableProducts(voucherCode: string) {
  const url = `/vouchers/${voucherCode}/redeemable-products`;
  return instance.get<RedeemableProductResponse[]>(url);
}

export async function switchCourse(
  voucherCode: string,
  data: SwitchCourseParams
) {
  const url = `vouchers/${voucherCode}/switch-course`;
  return instance.post<void>(url, data);
}

export async function switchGroup(data: SwitchGroupParams) {
  const url = `/vouchers/switch-group`;
  return instance.post<void>(url, data);
}

export async function bulkSwitchGroup(data: BulkSwitchGroupParams) {
  const url = `/vouchers/bulk-switch-group`;
  return instance.post<void>(url, data);
}

export async function resetPassword(email: string) {
  const url = "/customers/reset-password";
  return instance.post(url, { email });
}

export async function sendSeatEmail(data: SendSeatEmail[]) {
  const url = `/vouchers/send-seat-email`;
  return instance.post<void>(url, data);
}

export async function sendVoucherEmail(data: VoucherEmailParams) {
  const url = `/vouchers/send-voucher-email`;
  return instance.post<void>(url, data);
}

export async function fetchVoucherSummary(params: VoucherSummaryParams) {
  const url = `/vouchers/summary`;
  return instance.get<VoucherSummaryResponse>(url, { params });
}

// assign single vouchers to users
export async function assignVouchers(payload: VoucherAssignmentRequest) {
  const url = "/vouchers/assignments";
  return instance.post<void>(url, payload);
}

/* Revoke voucher by id */
export async function revoke(voucherId: number, data: RevokingRequest) {
  const url = `/vouchers/${voucherId}/revoking`;
  if (data?.assignmentId) {
    return instance.post<void>(url, data);
  }
  return instance.post<void>(url);
}

export async function revokingRedeemedSingle(voucherId: number) {
  const url = `/vouchers/${voucherId}/revoking-redeemed`;
  return instance.post<void>(url);
}

export async function reassignVoucher(payload: VoucherReassignmentRequest) {
  const url = "/vouchers/voucher-reassign";
  return instance.post<void>(url, payload);
}

export async function reassignRedeemedSingleVoucher(
  payload: VoucherReassignmentRequest
) {
  const url = "/vouchers/reassign-redeemed";
  return instance.post<void>(url, payload);
}

export async function reassignSeat(payload: SeatReassignRequest) {
  const url = "/vouchers/seat-reassign";
  return instance.post<void>(url, payload);
}

export async function exportAssignmentAndRedemptionList(
  params: VoucherSummaryParams
) {
  const url = "/vouchers/assignments-and-redemptions/export";
  return instance.get(url, { params, responseType: "arraybuffer" });
}

export async function exportAssignmentList(params: VoucherSummaryParams) {
  const url = "/vouchers/assignments/export";
  return instance.get(url, { params, responseType: "arraybuffer" });
}

export async function exportRedemptionList(params: VoucherSummaryParams) {
  const url = "/vouchers/redemptions/export";
  return instance.get(url, { params, responseType: "arraybuffer" });
}

export async function exportSeatAssignmentList(params: VoucherSummaryParams) {
  const url = "/vouchers/seat-assignments/export";
  return instance.get(url, { params, responseType: "arraybuffer" });
}

export async function getAvailableAmount(params: VoucherAvailableAmountParams) {
  const url = "/vouchers/available-amount";
  return instance.get<{ availableAmount: number }>(url, { params });
}

/* Distribution is only for SINGLE voucher */
export async function distributeVouchers(payload: VoucherDistributeRequest) {
  const url = "/vouchers/distributions";
  return instance.post<void>(url, payload);
}

export async function getVoucherCodeInformation(voucherCode: string) {
  const url = `/vouchers/${voucherCode}/voucher-info`;
  return instance.get<VoucherCodeInformation>(url);
}

export async function checkRenewableInvoice(
  adminGroupId: string,
  voucherGroupId: string
) {
  const url = `/vouchers/renew/check`;
  return instance.get<CheckRenewableInvoiceResponse[]>(url, {
    params: { adminGroupId, voucherGroupId },
  });
}

export async function getRenewableUserList(
  oldAdminGroupId: string,
  newAdminGroupId: string,
  oldVoucherGroupId: string,
  newVoucherGroupId: string,
  page: number,
  size: number,
  email?: string,
  renewStatuses?: RenewableStatus[]
) {
  const url = `/vouchers/renew/select`;
  return instance.get<RenewableUserListResponse>(url, {
    params: {
      oldAdminGroupId,
      newAdminGroupId,
      oldVoucherGroupId,
      newVoucherGroupId,
      page: page - 1,
      size,
      email,
      renewStatuses,
    },
  });
}

export async function confirmRenew(
  oldAdminGroupId: string,
  newAdminGroupId: string,
  oldVoucherGroupId: string,
  newVoucherGroupId: string,
  deletedIds: number[]
) {
  const url = `/vouchers/renew/confirm`;
  return instance.post<ConfirmRenewResponse>(url, {
    oldAdminGroupId,
    newAdminGroupId,
    oldVoucherGroupId,
    newVoucherGroupId,
    deletedIds,
  });
}
