import { ColumnType } from "antd/lib/table";
import { EMPTY } from "constants/common";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import { Checkbox, Tooltip } from "antd";
import { ExclamationOutlined } from "@ant-design/icons";
import {
  RenewableStatus,
  RenewableUser,
} from "../../../../types/dto/response/assignment";

const useColumns = (): [ColumnType<RenewableUser>[], number[]] => {
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const columns: ColumnType<RenewableUser>[] = [
    {
      title: "Customer email",
      dataIndex: "customerEmail",
      ellipsis: true,
      width: "20%",
      render: (value) => value || EMPTY,
    },
    {
      title: "Customer name",
      dataIndex: "customerName",
      ellipsis: true,
      width: "15%",
      render: (value) => value || EMPTY,
    },
    {
      title: "Expiration date",
      dataIndex: "currentExpiryDate",
      ellipsis: true,
      width: "15%",
      render: (value) => (value ? moment(value).format("YYYY-MM-DD") : EMPTY),
    },
    {
      title: "Course",
      dataIndex: "courseName",
      ellipsis: true,
      width: "20%",
      render: (value) => value || EMPTY,
    },
    {
      title: "Renewed status",
      dataIndex: "status",
      width: "16%",
      filters: [
        { text: "Unable to renew", value: RenewableStatus.UNABLE_TO_RENEW },
        { text: "Renewed", value: RenewableStatus.RENEWED },
        { text: "Not renewed", value: RenewableStatus.NOT_RENEWED },
      ],
      render: (value, record) => (
        <div>
          {record.status === RenewableStatus.UNABLE_TO_RENEW && (
            <Tooltip title="Course is unavailable. Please contact admin for support.">
              <ExclamationOutlined style={{ color: "red" }} />
            </Tooltip>
          )}
          <span>{_.capitalize(_.replace(value, /_/g, " ")) || EMPTY}</span>
        </div>
      ),
    },
    {
      title: "Action",
      width: "14%",
      ellipsis: true,
      render: (value, record) => {
        if (record.status !== RenewableStatus.NOT_RENEWED) {
          return null;
        }
        return (
          <Checkbox
            checked={!deletedIds.includes(record.renewSelectId)}
            onChange={(e) => {
              if (
                e.target.checked &&
                deletedIds.includes(record.renewSelectId)
              ) {
                setDeletedIds(
                  deletedIds.filter((id) => id !== record.renewSelectId)
                );
              }
              if (
                !e.target.checked &&
                !deletedIds.includes(record.renewSelectId)
              ) {
                setDeletedIds([...deletedIds, record.renewSelectId]);
              }
            }}
          />
        );
      },
    },
  ];

  return [columns, deletedIds];
};

export default useColumns;
