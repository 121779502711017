import { Modal, Space, Spin } from "antd";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import * as assignmentAPI from "services/assignment";
import { useParams } from "react-router";
import SelectInvoice from "./SelectInvoice";
import {
  CheckRenewableInvoiceResponse,
  ConfirmRenewResponse,
} from "../../../../types/dto/response/assignment";
import SelectUserTable from "./SelectUserTable";
import Message from "../../../../components/message";
import { useLoadingStore } from "../../../../hooks/useLoadingStore";

interface IProps {
  visible: boolean;
  onClose: (shouldRefresh: boolean) => void;
}

enum Step {
  STEP1 = "STEP1",
  STEP2 = "STEP2",
  STEP3 = "STEP3",
}

const stepToTitle = {
  [Step.STEP1]: "Step 1: Select an invoice",
  [Step.STEP2]: "Step 2: Select the user(s) for renewal",
  [Step.STEP3]: "Step 3: Renew successfully",
};

const stepToCancelText = {
  [Step.STEP1]: "Cancel",
  [Step.STEP2]: "Back to step 1",
  [Step.STEP3]: "Cancel",
};

const RenewLicenseModal = ({ visible, onClose }: IProps) => {
  const { customerGroupId, voucherGroupId } = useParams<{
    customerGroupId: string;
    voucherGroupId: string;
  }>();
  const [step, setStep] = useState<Step>(Step.STEP1);
  const renewableInvoiceRef = useRef<CheckRenewableInvoiceResponse>();
  const confirmRenewResponse = useRef<ConfirmRenewResponse>();
  const deleteIdsRef = useRef<number[]>([]);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const isLoading = useLoadingStore((state) => state.getIsLoading());

  const renewedCount = confirmRenewResponse.current?.renewedCount ?? 0;
  const renewedExpiryDate = confirmRenewResponse.current?.newExpiryDate
    ? moment(confirmRenewResponse.current.newExpiryDate).format("YYYY-MM-DD")
    : "open ended";

  const oldAdminGroupId = `${renewableInvoiceRef.current?.adminGroupId}`;
  const oldVoucherGroupId = renewableInvoiceRef.current?.voucherGroupId ?? "";

  useEffect(() => {
    renewableInvoiceRef.current = undefined;
    confirmRenewResponse.current = undefined;
    setStep(Step.STEP1);
  }, [visible]);

  const confirmRenew = () => {
    assignmentAPI
      .confirmRenew(
        oldAdminGroupId,
        customerGroupId,
        oldVoucherGroupId,
        voucherGroupId,
        deleteIdsRef.current
      )
      .then((response) => {
        confirmRenewResponse.current = response;
        setStep(Step.STEP3);
      })
      .catch(Message.error);
  };

  return (
    <Modal
      visible={visible}
      title={stepToTitle[step]}
      okText="Confirm"
      cancelText={stepToCancelText[step]}
      width={step === Step.STEP2 ? "70%" : "40%"}
      destroyOnClose={true}
      cancelButtonProps={{
        shape: "round",
        style: { minWidth: "92px" },
        id: "cancelButton",
        hidden: step === Step.STEP3,
      }}
      okButtonProps={{
        shape: "round",
        style: { minWidth: "92px" },
        disabled: confirmDisabled && step === Step.STEP2,
      }}
      maskClosable={false}
      onOk={() => {
        if (isLoading) {
          return;
        }
        if (step === Step.STEP1 && renewableInvoiceRef.current) {
          setStep(Step.STEP2);
          return;
        }
        if (step === Step.STEP2) {
          confirmRenew();
          return;
        }
        if (step === Step.STEP3) {
          onClose(true);
        }
      }}
      onCancel={(e: React.MouseEvent<HTMLElement>) => {
        if (step === Step.STEP2 && e.currentTarget.id === "cancelButton") {
          renewableInvoiceRef.current = undefined;
          setStep(Step.STEP1);
          return;
        }
        onClose(false);
      }}
    >
      <Spin spinning={isLoading}>
        {step === Step.STEP1 && (
          <SelectInvoice
            adminGroupId={customerGroupId}
            voucherGroupId={voucherGroupId}
            onInvoiceSelect={(invoice) =>
              (renewableInvoiceRef.current = invoice)
            }
          />
        )}
        {step === Step.STEP2 && (
          <SelectUserTable
            oldAdminGroupId={oldAdminGroupId}
            oldVoucherGroupId={oldVoucherGroupId}
            newAdminGroupId={customerGroupId}
            newVoucherGroupId={voucherGroupId}
            onDeletedIdsChanged={(newDeletedIds) =>
              (deleteIdsRef.current = newDeletedIds)
            }
            setConfirmDisabled={setConfirmDisabled}
          />
        )}
        {step === Step.STEP3 && (
          <Space>
            <span>
              {renewedCount} licenses have been successfully renewed, and the
              new expiration date is {renewedExpiryDate}
            </span>
          </Space>
        )}
      </Spin>
    </Modal>
  );
};

export default RenewLicenseModal;
