import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Form, Input, Row, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { now } from "moment";
import * as Icons from "assets/icons";
import { message, Modal } from "components";
import { DEFAULT_PAGINATION } from "constants/common";
import { RootState, useAppDispatch } from "app/store";
import { saveFile } from "utils/saveFile";
import AssignUserForm from "components/AssignUserForm";
import {
  AssignmentListParams,
  RedemptionListParams,
} from "types/dto/request/assignment";
import AssignInBulkModal from "../../AssignInBulkModal";
import {
  assignVouchers,
  exportAssignmentList,
  exportRedemptionList,
} from "../../voucherGroupsSlice";
import PopOverFilter, {
  EmailStatus,
  PopOverFilterProps,
} from "../PopOverFilter";
import { DeliveryStatus } from "../../../../types/model/global";

interface Props {
  isRedeem?: boolean;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setFilter: Dispatch<
    SetStateAction<RedemptionListParams | AssignmentListParams>
  >;
  resetFilter: () => void;
  filter: RedemptionListParams | AssignmentListParams;
  onRenewClick: () => void;
}

// used in MASTER voucher-group detail page.
function Toolbar({
  isRedeem,
  email,
  setEmail,
  filter,
  setFilter,
  resetFilter,
  onRenewClick,
}: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { customerGroupId, voucherGroupId } = useParams<{
    customerGroupId: string;
    voucherGroupId: string;
  }>();
  const adminGroupId = parseInt(customerGroupId);
  const loading = useSelector((state: RootState) => state.loading);
  const { voucherSummary } = useSelector(
    (state: RootState) => state.voucherGroups
  );
  const { expirationDate, isOpenEnded } = voucherSummary;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSearchByEmail = () => {
    if (isRedeem) {
      setFilter((prevState) => ({
        ...prevState,
        ...DEFAULT_PAGINATION,
        redeemerEmail: email?.trim(),
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        ...DEFAULT_PAGINATION,
        assigneeEmail: email?.trim(),
      }));
    }
    setEmail((prevState) => prevState.trim());
  };

  const handleRefresh = () => {
    setFilter((prevState) => ({
      ...prevState,
      ...DEFAULT_PAGINATION,
    }));
  };

  const handleExport = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to export?",
      onOk: () => {
        if (isRedeem) {
          dispatch(exportRedemptionList({ adminGroupId, voucherGroupId }))
            .then(unwrapResult)
            .then((content: any) => saveFile(content, "redemption"))
            .catch(message.error);
        } else {
          dispatch(exportAssignmentList({ adminGroupId, voucherGroupId }))
            .then(unwrapResult)
            .then((content: any) => saveFile(content, "assignment"))
            .catch(message.error);
        }
      },
    });
  };

  const isCodeInvalid = () => {
    if (!isOpenEnded && expirationDate < now()) {
      return message.error(`The code has expired.`);
    }
    return false;
  };

  const handleAssignUser = () => {
    if (!isCodeInvalid()) {
      Modal.confirm({
        title: "Assign user",
        content: <AssignUserForm form={form} />,
        okText: "Confirm",
        onOk: () =>
          form
            .validateFields()
            .then((values) =>
              dispatch(
                assignVouchers({ ...values, adminGroupId, voucherGroupId })
              )
                .then(unwrapResult)
                .then(resetFilter)
            )
            .catch(message.error),
        afterClose: () => form.resetFields(),
      });
    }
  };

  const onFinish = () => {
    resetFilter();
    setVisible(false);
  };

  const handleAssignBulk = () => {
    if (!isCodeInvalid()) {
      setVisible(!visible);
    }
  };

  const handleSearchPopOverFilter: PopOverFilterProps["onSubmit"] = (
    values
  ) => {
    setFilter((prevState) => ({
      ...prevState,
      page: undefined,
      inactiveDays: values.inactiveDays,
      entitlementRemainingDays: values.entitlementRemainingDays,
      hasSentEmail:
        values.emailStatus === EmailStatus.NotSent ? false : undefined,
      deliveryStatuses:
        values.emailStatus === EmailStatus.Failed
          ? [DeliveryStatus.BOUNCE, DeliveryStatus.FAILED]
          : undefined,
    }));
  };

  return (
    <>
      <Row
        justify="space-between"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <Space size={21}>
          <Input
            allowClear
            prefix={<SearchOutlined />}
            value={email}
            onPressEnter={handleSearchByEmail}
            onBlur={() => setEmail((prevState) => prevState.trim())}
            onChange={handleInputChange}
            placeholder={
              isRedeem ? "Search redeemed email" : "Search assigned email"
            }
            style={{ width: "360px" }}
          />
          <PopOverFilter
            voucherType={"MASTER"}
            filter={filter}
            onSubmit={handleSearchPopOverFilter}
            showInactiveDays={isRedeem}
            showEntitlementRemainingDays={isRedeem}
            showEmailStatus={!isRedeem}
          />
        </Space>
        <Space>
          {!isRedeem && (
            <>
              <Button type="default" shape="round" onClick={onRenewClick}>
                Renew expired/expiring licenses
              </Button>
              <Button type="default" shape="round" onClick={handleAssignUser}>
                Assign user
              </Button>
              <Button type="default" shape="round" onClick={handleAssignBulk}>
                Assign in bulk
              </Button>
            </>
          )}
          <Button type="default" shape="round" onClick={handleExport}>
            Export
          </Button>
          <Button
            type="text"
            shape="circle"
            icon={<Icons.Refresh />}
            onClick={handleRefresh}
          />
        </Space>
      </Row>

      <AssignInBulkModal
        visible={visible}
        adminGroupId={adminGroupId}
        voucherGroupId={voucherGroupId}
        voucherType={"MASTER"}
        confirmLoading={loading}
        onFinish={onFinish}
        onCancel={() => setVisible(false)}
        maskClosable={false}
        centered={true}
      />
    </>
  );
}

export default Toolbar;
