import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Spin } from "antd";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "app/store";
import { message } from "components";
import { EMPTY } from "constants/common";
import VoucherAssignment from "./VoucherAssignment";
import MasterVoucherAssignment from "./MasterVoucherAssignment";
import {
  fetchVoucherSummary,
  setFilter,
  voucherGroupsSelectors,
} from "../voucherGroupsSlice";
import VoucherSummary from "../VoucherSummary";

function VoucherDistributionDetail() {
  const dispatch = useAppDispatch();
  const { customerGroupId, voucherGroupId } = useParams<{
    customerGroupId: string;
    voucherGroupId: string;
  }>();
  const adminGroupId = parseInt(customerGroupId);
  const voucherGroup = useSelector((state: RootState) =>
    voucherGroupsSelectors.selectById(state, voucherGroupId)
  );

  const { voucherSummary } = useSelector(
    (state: RootState) => state.voucherGroups
  );
  const isLoading = useSelector((state: RootState) => state.loading);
  const { description, invoice } = voucherSummary;

  useEffect(() => {
    dispatch(
      fetchVoucherSummary({
        voucherGroupId,
        adminGroupId,
      })
    )
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, voucherGroupId, adminGroupId]);

  useEffect(() => {
    dispatch(
      setFilter({
        voucherGroupId,
        adminGroupId,
        voucherType: voucherGroup?.voucherType!,
      })
    );
  }, [dispatch, voucherGroupId, voucherGroup]);

  return (
    <Card
      title={
        <>
          <div>{invoice || EMPTY}</div>
          <div style={{ color: "#858585", fontSize: "16px" }}>
            Vouchers description: {description}
          </div>
        </>
      }
      className="page-container"
    >
      <Spin spinning={isLoading} size={"large"}>
        <VoucherSummary
          voucherType={voucherGroup?.voucherType}
          voucherCode={voucherGroup?.voucherCode}
          totalAmount={voucherGroup?.totalAmount}
          assignedAmount={voucherGroup?.assignedAmount}
          redeemedAmount={voucherGroup?.redeemedAmount}
        />
        {voucherGroup?.voucherType === "MASTER" ? (
          <MasterVoucherAssignment />
        ) : (
          <VoucherAssignment
            voucherType={voucherGroup?.voucherType!}
            voucherCode={voucherGroup?.voucherCode!}
          />
        )}
      </Spin>
    </Card>
  );
}

export default VoucherDistributionDetail;
