import React, { ChangeEvent, useState } from "react";
import { Button, Row, Space, Table } from "antd";
import { useMount, useUpdateEffect } from "react-use";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { DEFAULT_PAGINATION } from "constants/common";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { ColumnType } from "antd/lib/table";
import { RootState, useAppDispatch } from "app/store";
import { message, Modal } from "components";
import { AssignmentAndRedemptionListParams } from "types/dto/request/assignment";
import {
  AssignmentAndRedemptionModel,
  VoucherType,
} from "types/model/assignment";
import { DeliveryStatus } from "types/model/global";
import { saveFile } from "utils/saveFile";
import moment, { now } from "moment";
import {
  exportAssignmentAndRedemptionList,
  exportSeatAssignmentList,
  fetchAssignmentAndRedemptionList,
  fetchSeatAssignmentList,
  voucherGroupsSelectors,
} from "../../voucherGroupsSlice";
import * as Icons from "../../../../assets/icons";
import useColumns from "../useColumns";
import AssignInBulkModal from "../../AssignInBulkModal";
import ResizableTitle from "../../../../components/ResizableTitle";
import styles from "../VoucherAssignment.module.scss";
import PopOverFilter, {
  EmailStatus,
  PopOverFilterProps,
} from "../PopOverFilter";
import AssignUserModal from "./AssignUserModal";
import InputForSingle, { OptionValue } from "./components/InputForSingle";
import InputForSeat from "./components/InputForSeat";
import { selectCurrentGroup } from "../../../customerGroup/customerGroupsSlice";
import RenewLicenseModal from "../RenewLicenseModal";

interface Props {
  voucherType: VoucherType;
  voucherCode: string;
}

export default function VoucherAssignment({ voucherType, voucherCode }: Props) {
  const [query, setQuery] = useQueryParams({
    voucherCode: withDefault(StringParam, undefined),
    assigneeEmail: withDefault(StringParam, undefined),
    redeemerEmail: withDefault(StringParam, undefined),
  });
  const dispatch = useAppDispatch();
  const { customerGroupId, voucherGroupId } = useParams<{
    customerGroupId: string;
    voucherGroupId: string;
  }>();
  const adminGroupId = parseInt(customerGroupId);
  const currentGroup = useSelector(selectCurrentGroup);
  const [visibleForAssignInBulk, setVisibleForAssignInBulk] = useState(false);
  const [visibleForAssignUserModal, setVisibleForAssignUserModal] = useState(
    false
  );
  const [inputValue, setInputValue] = useState(
    query.voucherCode || query.assigneeEmail || query.redeemerEmail || ""
  );
  const [inputType, setInputType] = useState<OptionValue>(
    _.isEmpty(query.redeemerEmail) ? "voucherCode" : "redeemedEmail"
  );

  const {
    currentSize,
    currentPage,
    totalElements,
    assignmentAndRedemptionList,
    seatAssignmentList,
    voucherSummary,
  } = useSelector((state: RootState) => state.voucherGroups);
  const { startDate, expirationDate, isOpenEnded } = voucherSummary;
  const isLoading = useSelector((state: RootState) => state.loading);
  const initialFilterState = {
    ...DEFAULT_PAGINATION,
    voucherGroupId: voucherGroupId,
    adminGroupId: adminGroupId,
    redeemerEmail: query.redeemerEmail,
  };
  const [filter, setFilter] = useState<AssignmentAndRedemptionListParams>(
    initialFilterState
  );

  const resetFilter = () => {
    setInputValue("");
    setFilter({ ...initialFilterState });
  };

  const { voucherColumnConfig, seatColumnConfig } = useColumns(
    voucherType,
    voucherGroupId,
    adminGroupId,
    resetFilter
  );

  const [singleVoucherColumn, setSingleVoucherColumn] = useState<
    ColumnType<AssignmentAndRedemptionModel>[]
  >(voucherColumnConfig);

  const [seatVoucherColumn, setSeatVoucherColumn] = useState<
    ColumnType<AssignmentAndRedemptionModel>[]
  >(seatColumnConfig);

  const voucherGroup = useSelector((state: RootState) =>
    voucherGroupsSelectors.selectById(state, voucherGroupId)
  );

  const [showRenewModal, setShowRenewModal] = useState(false);

  const handleSingleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...singleVoucherColumn];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setSingleVoucherColumn(nextColumns);
  };

  const handleSeatResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...seatVoucherColumn];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setSeatVoucherColumn(nextColumns);
  };

  const singleColumn = singleVoucherColumn.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<AssignmentAndRedemptionModel>) =>
      ({
        width: column.width,
        onResize: handleSingleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  const seatColumn = seatVoucherColumn.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<AssignmentAndRedemptionModel>) =>
      ({
        width: column.width,
        onResize: handleSeatResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  useMount(() => {
    //If jump from admin portal, query.voucherCode will be the voucherCode in url(SINGLE Voucher) or undefined(SEAT Voucher);
    //query.assigneeEmail will be the assigneeEmail in url(SEAT Voucher) or undefined(SINGLE Voucher);
    //If from B2B assignment, query.voucherCode and query.assigneeEmail will be undefined.
    setFilter((prevState) => ({
      ...prevState,
      voucherCode: query.voucherCode,
      assigneeEmail: query.assigneeEmail,
      redeemerEmail: query.redeemerEmail,
    }));
  });

  useUpdateEffect(() => {
    //the first 2 renderings completed in a flash
    //which means after useMount, it will immediatly executed useUpdateEffect.
    if (voucherType === "SINGLE") {
      dispatch(fetchAssignmentAndRedemptionList({ ...filter, adminGroupId }))
        .then(unwrapResult)
        .catch(message.error);
    } else if (voucherType === "SEAT") {
      if (_.isEmpty(filter.redeemerEmail)) {
        dispatch(fetchSeatAssignmentList({ ...filter, adminGroupId }))
          .then(unwrapResult)
          .catch(message.error);
      } else {
        dispatch(
          fetchSeatAssignmentList({
            ...filter,
            adminGroupId,
            assigneeEmail: filter.redeemerEmail,
            redeemerEmail: undefined,
          })
        )
          .then(unwrapResult)
          .catch(message.error);
      }
    }
  }, [dispatch, filter, voucherType, adminGroupId]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSearchEvent = () => {
    if (voucherType === "SINGLE") {
      if (inputType === "voucherCode") {
        setFilter((prevState) => ({
          ...prevState,
          page: undefined,
          voucherCode: inputValue?.trim(),
          assigneeEmail: undefined,
          redeemerEmail: undefined,
        }));
      } else if (inputType === "assignedEmail") {
        setFilter((prevState) => ({
          ...prevState,
          page: undefined,
          voucherCode: undefined,
          assigneeEmail: inputValue?.trim(),
          redeemerEmail: undefined,
        }));
      } else if (inputType === "redeemedEmail") {
        setFilter((prevState) => ({
          ...prevState,
          page: undefined,
          voucherCode: undefined,
          assigneeEmail: undefined,
          redeemerEmail: inputValue?.trim(),
        }));
      }
    } else {
      setFilter((prevState) => ({
        ...prevState,
        page: undefined,
        assigneeEmail: inputValue?.trim(),
        redeemerEmail: undefined,
      }));
    }
    setInputValue((prevState) => prevState.trim());
    setQuery((prevState) => ({
      ...prevState,
      redeemerEmail: undefined,
    }));
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const handleExport = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to export?",
      onOk: () => {
        if (voucherType === "SINGLE") {
          dispatch(
            exportAssignmentAndRedemptionList({ adminGroupId, voucherGroupId })
          )
            .then(unwrapResult)
            .then((content: any) => saveFile(content, "assignment_redemption"))
            .catch(message.error);
        } else {
          dispatch(exportSeatAssignmentList({ adminGroupId, voucherGroupId }))
            .then(unwrapResult)
            .then((content: any) => saveFile(content, "assignment"))
            .catch(message.error);
        }
      },
    });
  };

  const isCodeInvalid = () => {
    if (!isOpenEnded && startDate > now() && voucherType === "SEAT") {
      return message.error(
        `The code is not available before ${moment(startDate).format(
          "YYYY-MM-DD"
        )}.`
      );
    } else if (!isOpenEnded && expirationDate < now()) {
      return message.error(`The code has expired.`);
    }
    return false;
  };

  const handleAssignUser = () => {
    setQuery((prevState) => ({
      ...prevState,
      redeemerEmail: undefined,
    }));
    if (!isCodeInvalid()) {
      setVisibleForAssignUserModal(true);
    }
  };

  const onFinish = () => {
    resetFilter();
    setVisibleForAssignInBulk(false);
  };

  const handleAssignBulk = () => {
    if (!isCodeInvalid()) {
      setVisibleForAssignInBulk(!visibleForAssignInBulk);
    }
  };

  const handleSearchPopOverFilter: PopOverFilterProps["onSubmit"] = (
    values
  ) => {
    setFilter((prevState) => ({
      ...prevState,
      page: undefined,
      hasAssigned: values.hideAssigned ? false : undefined,
      hasRedeemed: values.hideRedeemed ? false : undefined,
      inactiveDays: values.inactiveDays,
      entitlementRemainingDays: values.entitlementRemainingDays,
      hasSentEmail:
        values.emailStatus === EmailStatus.NotSent ? false : undefined,
      deliveryStatuses:
        values.emailStatus === EmailStatus.Failed
          ? [DeliveryStatus.BOUNCE, DeliveryStatus.FAILED]
          : undefined,
    }));
  };

  const onRenewClick = () => {
    if (voucherGroup?.hasExpired) {
      message.error("The code has expired. Please contact admin for support.");
      return;
    }

    if (
      voucherGroup?.totalAmount &&
      voucherGroup?.totalAmount - (voucherGroup?.assignedAmount ?? 0) < 1
    ) {
      message.error("No available licenses.Please contact admin for support..");
      return;
    }
    setShowRenewModal(true);
  };

  return (
    <div className={styles.voucherAssignment}>
      <Row
        justify="space-between"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <Space size={21}>
          {voucherType === "SINGLE" && (
            <InputForSingle
              value={inputValue}
              onPressEnter={handleSearchEvent}
              onBlur={() => setInputValue((prevState) => prevState.trim())}
              onChange={handleInputChange}
              selectedValue={inputType}
              onSelectedValueChange={(val) => {
                setInputType(val);
                setInputValue("");
              }}
            />
          )}
          {voucherType === "SEAT" && (
            <InputForSeat
              value={inputValue}
              onPressEnter={handleSearchEvent}
              onBlur={() => setInputValue((prevState) => prevState.trim())}
              onChange={handleInputChange}
            />
          )}
          <PopOverFilter
            voucherType={voucherType}
            filter={filter}
            onSubmit={handleSearchPopOverFilter}
            showInactiveDays
            showEntitlementRemainingDays
            showEmailStatus
          />
        </Space>
        <Space>
          {!currentGroup?.isRegardedAsB2c && (
            <>
              <Button type="default" shape="round" onClick={onRenewClick}>
                Renew expired/expiring licenses
              </Button>
              <Button type="default" shape="round" onClick={handleAssignUser}>
                Assign user
              </Button>
              <Button type="default" shape="round" onClick={handleAssignBulk}>
                Assign in bulk
              </Button>
            </>
          )}
          <Button type="default" shape="round" onClick={handleExport}>
            Export
          </Button>
          <Button
            type="text"
            shape="circle"
            icon={<Icons.Refresh />}
            onClick={handleSearchEvent}
          />
        </Space>
      </Row>
      {voucherType === "SINGLE" && (
        <Table
          dataSource={assignmentAndRedemptionList}
          columns={singleColumn}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          rowKey={(record) => record.voucherId}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}~${range[1]} of ${total} items`,
            total: totalElements,
            current: currentPage,
            pageSize: currentSize,
            showSizeChanger: true,
            onChange: handlePaginationChange,
          }}
        />
      )}
      {voucherType === "SEAT" && (
        <Table
          dataSource={seatAssignmentList}
          columns={seatColumn}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          rowKey={(record) => record.assignmentId}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}~${range[1]} of ${total} items`,
            total: totalElements,
            current: currentPage,
            pageSize: currentSize,
            showSizeChanger: true,
            onChange: handlePaginationChange,
          }}
        />
      )}

      <AssignInBulkModal
        visible={visibleForAssignInBulk}
        adminGroupId={adminGroupId}
        voucherGroupId={voucherGroupId}
        voucherType={voucherType}
        confirmLoading={isLoading}
        onFinish={onFinish}
        onCancel={() => setVisibleForAssignInBulk(false)}
        maskClosable={false}
        centered={true}
      />
      <AssignUserModal
        visible={visibleForAssignUserModal}
        close={() => setVisibleForAssignUserModal(false)}
        voucherType={voucherType}
        adminGroupId={adminGroupId}
        voucherGroupId={voucherGroupId}
        resetFilter={resetFilter}
        voucherCode={voucherCode}
      />
      <RenewLicenseModal
        visible={showRenewModal}
        onClose={(shouldRefresh) => {
          setShowRenewModal(false);
          if (shouldRefresh) {
            resetFilter();
          }
        }}
      />
    </div>
  );
}
