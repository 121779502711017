import { Input, Modal, Row, Space, Table, TableProps } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SorterResult } from "antd/es/table/interface";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment/moment";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import styles from "./UserList.module.scss";
import useColumns from "./columnConfig";
import { RootState, useAppDispatch } from "../../../../../app/store";
import { UserListFormModel } from "../../../../../types/model/reporting";
import {
  fetchCustomerLearningTimeStatistics,
  userListSelectors,
} from "../../../userListSlice";
import { message, Modal as ModalComponents } from "../../../../../components";
import { camelCaseToUnderscore } from "../../../../../utils/parseXLSX";
import UserListFilter, { UserListFilterProps } from "./UserListFilter";
import {
  CustomerLearningTimeStatisticsParams,
  ScheduleExportInfo,
} from "../../../../../types/dto/request/reporting";
import {
  fetchScheduleExportLessonProgress,
  removeScheduleExportLessonProgress,
} from "../../../reportingSlice";
import ScheduledReports from "../ScheduledReports";
import ScheduledExportButton from "../ScheduledExportButton";
import ExportNow from "../ExportNow";
import DownloadReports from "../DownloadReports";
import {
  ChooseAssignmentTable,
  IChooseAssignmentTable,
} from "./ChooseAssignmentTable";

export default function TabUserList() {
  const dispatch = useAppDispatch();
  const customerLearningTimeStatistics = useSelector(
    userListSelectors.selectAll
  );
  const { currentPage, currentSize, totalElements } = useSelector(
    (state: RootState) => state.userList
  );
  const { currentGroupId: customerGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 10),
    customerGroupId: withDefault(NumberParam, customerGroupId),
    sort: withDefault(StringParam, "last_activity_date,DESC"),
    customerEmail: withDefault(StringParam, ""),
    customerName: withDefault(StringParam, undefined),
    courseName: withDefault(StringParam, undefined),
    licenseStatus: withDefault(StringParam, undefined),
    firstActivityDateFrom: withDefault(NumberParam, undefined),
    firstActivityDateTo: withDefault(NumberParam, undefined),
    lastActivityDateFrom: withDefault(NumberParam, undefined),
    lastActivityDateTo: withDefault(NumberParam, undefined),
    openDownloadReports: withDefault(BooleanParam, false),
  });
  const [email, setEmail] = useState(query.customerEmail);
  const [onlyLast30Days, setOnlyLast30Days] = useState(false);
  const initScheduleExportInfo: ScheduleExportInfo = {
    expired: false,
    excelTypeEnum: "CSV",
    cronExpression: "",
    beginDate: null,
    endDate: null,
    scheduleExportType: "USER_LIST",
    createdDate: 0,
    includeFields: [],
    reminderEmails: [],
    fileNamePrefix: "pimsleur",
  };
  const [scheduleExportInfo, setScheduleExportInfo] = useState<
    ScheduleExportInfo
  >(initScheduleExportInfo);
  const [scheduleAReportVisible, setScheduleAReportVisible] = useState(false);
  const [exportNowVisible, setExportNowVisible] = useState(false);
  const fieldOptions = [
    "Customer email",
    "Customer name",
    "Learning time(All time)",
    "Learning time(Last 30 days)",
    "First activity date",
    "Last activity date",
    "Current course",
    "License status",
  ];
  const [isShake, setIsShake] = useState(false);
  const [chooseAssignmentVisible, setChooseAssignmentVisible] = useState(false);
  const [assignmentData, setAssignmentData] = useState<
    IChooseAssignmentTable[]
  >([]);
  const [redeemerEmail, setRedeemerEmail] = useState("");

  useEffect(() => {
    setIsShake(true);
    const timer = setTimeout(() => {
      setIsShake(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setEmail(query.customerEmail);
    dispatch(
      fetchCustomerLearningTimeStatistics({
        ...query,
        customerGroupId: customerGroupId,
      })
    )
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, query, customerGroupId]);

  const updateScheduleExportInfo = (isDisplayToast: boolean) => {
    dispatch(
      fetchScheduleExportLessonProgress({
        customerGroupId: customerGroupId,
        scheduleExportType: "USER_LIST",
      })
    )
      .then(unwrapResult)
      .then((data) => {
        setScheduleExportInfo(data);
        setIsShake(true);
        setTimeout(() => {
          setIsShake(false);
        }, 5000);
      })
      .then(
        () =>
          isDisplayToast &&
          message.success(
            "Success! Your report is now scheduled and will be promptly delivered to your email at the specified time."
          )
      )
      .catch(message.error);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateScheduleExportInfo(false), [customerGroupId, dispatch]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePressEnter = () => {
    setQuery((prevState) => ({
      ...prevState,
      page: undefined,
      customerEmail: email.trim(),
    }));
  };

  const handleTableChange: TableProps<UserListFormModel>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const sortColumn = sorter as SorterResult<UserListFormModel>;
    if ("sort" === extra.action) {
      setQuery((prevState) => ({
        ...prevState,
        page: undefined,
        sort: `${camelCaseToUnderscore(sortColumn.field as string)},${
          sortColumn.order === "ascend" ? "ASC" : "DESC"
        }`,
      }));
    }
  };

  const handleOnSubmit: UserListFilterProps["onSubmit"] = (values) => {
    setQuery({
      ...query,
      ...values,
      customerEmail: values.customerEmail?.trim(),
      customerName: values.customerName?.trim(),
      courseName: values.courseName?.trim(),
      licenseStatus: values.licenseStatus?.trim(),
      firstActivityDateFrom: values.firstActivityDateFrom
        ? moment(values.firstActivityDateFrom).startOf("D").valueOf()
        : undefined,
      firstActivityDateTo: values.firstActivityDateTo
        ? moment(values.firstActivityDateTo).endOf("D").valueOf()
        : undefined,
      lastActivityDateFrom: values.lastActivityDateFrom
        ? moment(values.lastActivityDateFrom).startOf("D").valueOf()
        : undefined,
      lastActivityDateTo: values.lastActivityDateTo
        ? moment(values.lastActivityDateTo).endOf("D").valueOf()
        : undefined,
      page: 0,
    });
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setQuery((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const handleDeleteScheduledExport = () => {
    ModalComponents.confirm({
      title: "Confirm",
      content: "Are you sure you want to stop scheduling a report?",
      onOk: () => {
        dispatch(
          removeScheduleExportLessonProgress({
            customerGroupId: customerGroupId,
            scheduleExportType: "USER_LIST",
          })
        )
          .then(unwrapResult)
          .catch(message.error)
          .then(() => {
            setScheduleExportInfo(initScheduleExportInfo);
            setScheduleAReportVisible(false);
          });
      },
    });
  };

  return (
    <>
      <Row justify={"space-between"} style={{ margin: "0 0 25px" }}>
        <Space size={21}>
          <Input
            allowClear
            value={email}
            placeholder="Search email"
            className={styles.email}
            onChange={handleInputChange}
            onBlur={() => setEmail((prevState) => prevState.trim())}
            onPressEnter={handlePressEnter}
          />
          <UserListFilter
            initialValues={
              {
                ...query,
                customerEmail: email,
              } as CustomerLearningTimeStatisticsParams
            }
            onSubmit={handleOnSubmit}
          />
        </Space>
        <ScheduledExportButton
          scheduleExportInfo={scheduleExportInfo}
          setScheduleAReportVisible={setScheduleAReportVisible}
          handleDeleteScheduledExport={handleDeleteScheduledExport}
          setExportNowVisible={setExportNowVisible}
          setDownloadReportsVisible={(v) => {
            setQuery((prevState) => ({
              ...prevState,
              openDownloadReports: v,
            }));
          }}
          isShake={isShake}
        />
      </Row>
      <Table
        className={styles.table}
        columns={useColumns(
          onlyLast30Days,
          setOnlyLast30Days,
          setChooseAssignmentVisible,
          setAssignmentData,
          setRedeemerEmail
        )}
        dataSource={customerLearningTimeStatistics}
        onChange={handleTableChange}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}~${range[1]} of ${total} items`,
          total: totalElements,
          showSizeChanger: true,
          current: currentPage,
          pageSize: currentSize,
          onChange: handlePaginationChange,
        }}
      />
      <Modal
        title="Schedule a report"
        visible={scheduleAReportVisible}
        footer={null}
        onCancel={() => setScheduleAReportVisible(false)}
        destroyOnClose={true}
        maskClosable={false}
        width={600}
      >
        <ScheduledReports
          customerGroupId={customerGroupId}
          dispatch={dispatch}
          handleCloseModal={() => setScheduleAReportVisible(false)}
          scheduleExportInfo={scheduleExportInfo}
          updateScheduleExportInfo={updateScheduleExportInfo}
          handleDeleteScheduledExport={handleDeleteScheduledExport}
          isDisplayDelete={
            !scheduleExportInfo.expired &&
            scheduleExportInfo.cronExpression?.length > 0
          }
          scheduleExportType="USER_LIST"
          options={fieldOptions}
        />
      </Modal>
      <Modal
        title="Export a report now"
        visible={exportNowVisible}
        footer={null}
        onCancel={() => setExportNowVisible(false)}
        destroyOnClose={true}
        maskClosable={false}
        width={600}
      >
        <ExportNow
          dispatch={dispatch}
          customerGroupId={customerGroupId}
          handleCloseModal={() => setExportNowVisible(false)}
          scheduleExportType="USER_LIST"
          options={fieldOptions}
        />
      </Modal>
      <Modal
        title="Download your scheduled reports"
        visible={query.openDownloadReports}
        footer={null}
        onCancel={() =>
          setQuery((prevState) => ({
            ...prevState,
            openDownloadReports: false,
          }))
        }
        destroyOnClose={true}
        maskClosable={false}
        width={800}
      >
        <DownloadReports
          adminGroupId={customerGroupId}
          dispatch={dispatch}
          scheduleExportType="USER_LIST"
        />
      </Modal>
      <Modal
        title="Choose Assignment"
        visible={chooseAssignmentVisible}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        width={1000}
        onCancel={() => setChooseAssignmentVisible(false)}
      >
        <ChooseAssignmentTable
          tableData={assignmentData}
          redeemerEmail={redeemerEmail}
          handleOpenModal={setChooseAssignmentVisible}
        />
      </Modal>
    </>
  );
}
