import { Input, Space, Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import * as assignmentAPI from "services/assignment";
import styles from "./SelectUserTable.module.scss";
import {
  RenewableStatus,
  RenewableUser,
  RenewableUserListResponse,
} from "../../../../types/dto/response/assignment";
import useColumns from "./useColumns";
import Message from "../../../../components/message";

interface IProps {
  oldAdminGroupId: string;
  newAdminGroupId: string;
  oldVoucherGroupId: string;
  newVoucherGroupId: string;
  onDeletedIdsChanged: (ids: number[]) => void;
  setConfirmDisabled: (enabled: boolean) => void;
}

interface TableConfig {
  page?: number;
  pageSize?: number;
  email?: string;
  renewStatuses?: RenewableStatus[];
}

const DEFAULT_PAGE_CONFIG = {
  page: 1,
  pageSize: 10,
};

const SelectUserTable = ({
  oldAdminGroupId,
  newAdminGroupId,
  oldVoucherGroupId,
  newVoucherGroupId,
  onDeletedIdsChanged,
  setConfirmDisabled,
}: IProps) => {
  const [pageConfig, setPageConfig] = useState<TableConfig>(
    DEFAULT_PAGE_CONFIG
  );
  const [email, setEmail] = useState("");
  const [data, setData] = useState<RenewableUserListResponse>();
  const [columns, deletedIds] = useColumns();

  useEffect(() => onDeletedIdsChanged(deletedIds), [
    JSON.stringify(deletedIds),
  ]);

  useEffect(() => {
    assignmentAPI
      .getRenewableUserList(
        oldAdminGroupId,
        newAdminGroupId,
        oldVoucherGroupId,
        newVoucherGroupId,
        pageConfig.page ?? DEFAULT_PAGE_CONFIG.page,
        pageConfig.pageSize ?? DEFAULT_PAGE_CONFIG.pageSize,
        pageConfig.email,
        pageConfig.renewStatuses
      )
      .then((response) => {
        setData(response);
        setConfirmDisabled(!response.isRenewable);
      })
      .catch(Message.error);
  }, [JSON.stringify(pageConfig)]);

  const onTableChange: TableProps<RenewableUser>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === "filter") {
      setPageConfig({
        ...pageConfig,
        renewStatuses: filters.status
          ? (filters.status as RenewableStatus[])
          : [],
      });
    }
  };

  return (
    <Space direction={"vertical"} className={styles.space} size={"middle"}>
      <div className={styles.headerContainer}>
        <span>
          Note: Their credentials, selected language and learning progress
          remain unchanged.
        </span>
        <span>
          Renewed: {data?.renewedCount ?? "-"}/{data?.totalElements ?? "-"}
        </span>
      </div>
      <Input
        allowClear
        value={email}
        prefix={<SearchOutlined />}
        className={styles.input}
        onChange={(e) => setEmail(e.target.value)}
        onPressEnter={() => {
          const newEmail = email?.trim();
          setEmail(newEmail);
          setPageConfig((prev) => ({ ...prev, email: newEmail }));
        }}
        onBlur={() => setEmail((prevState) => prevState?.trim())}
        placeholder="Search email"
      />
      <Table
        columns={columns}
        dataSource={data?.content}
        className={styles.table}
        rowKey={(record) => record.renewSelectId}
        onChange={onTableChange}
        pagination={{
          total: data?.totalElements,
          showSizeChanger: true,
          current: pageConfig.page,
          pageSize: pageConfig.pageSize,
          onChange: (page, pageSize) =>
            setPageConfig({ ...pageConfig, page, pageSize }),
        }}
      />
    </Space>
  );
};

export default SelectUserTable;
